import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CountdownPage from './pages/countdown-page';
import FloorplanPage from './pages/floorplan-page';
import MitentscheidenPage from './pages/mitentscheiden-page';
import EinblickePage from './pages/einblicke-page';
import LoungeBibliothekPage from './pages/lounge_bibliothek-page';
import VorfreudeStatementsPage from './pages/vorfreude-statements-page';
import MeetingCollaborationPage from './pages/meeting-collaboration-page';
//import TelefoneboxesPage from './pages/telefoneboxes-page';
import TelefoneboxesV2Page from './pages/telefoneboxes-v2-page';
import MakingOfWKEinsPage from './pages/making_of_wkeins-page';
//import {GlobalProvider} from './utilities/GlobalContext'
import GA from './utilities/GoogleAnalytics';

// import Container from 'react-bootstrap/Container'
// import ContentBlock from './components/content-block'
// import FooterBlock from './components/footer-block'

class App extends Component {

	globals = {}
	
	render() {
		return (
			//<GlobalProvider value={this.globals}>
				<Router /*basename='/Project/WK_HQ/'*/>
					{ GA.init() && <GA.RouteTracker /> }
					<Switch>
						<Route path="/" exact component={CountdownPage} />
						<Route path="/floorplan" component={FloorplanPage} />
						<Route path="/mitentscheiden" component={MitentscheidenPage} />
						<Route path="/einblicke" component={EinblickePage} />
						<Route path="/lounge_bibliothek" component={LoungeBibliothekPage} />
						<Route path="/vorfreude-statements" component={VorfreudeStatementsPage} />
						<Route path="/meeting_collaboration" component={MeetingCollaborationPage} />
						{/* <Route path="/telefoneboxes" component={TelefoneboxesPage} /> */}
						<Route path="/telefoneboxes" component={TelefoneboxesV2Page} />
						<Route path="/making_of_wkeins" component={MakingOfWKEinsPage} />
						<Route render={() => <h2>Seite nicht gefunden</h2>} />
					</Switch>
				</Router>
			//</GlobalProvider>
		)
	}
}

export default App
