import React, {Component} from 'react'
import CountdownButton from './countdown-button'

class CountdownButtons extends Component {

	stepButtons = () => {
		const btns = []
		for(let i = this.props.numberOfSteps; i >= 0; i--) {
			btns.push(<CountdownButton 
				stepNumber={i} currentCountdownStep={this.props.currentCountdownStep} minCountdownStep={this.props.minCountdownStep}
				key={i}
				callback={this.props.callback}
				/>)
		}
		return btns
	}


	render () {
		return <ul className="countdown-buttons">
			{this.stepButtons()}
		</ul>
	}
}

export default CountdownButtons
