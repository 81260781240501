import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { Player } from 'video-react'

import 'video-react/styles/scss/video-react.scss'

export default class MakingOfWKEinsBlock extends Component {

	constructor () {
		super()
		this.state = {
			modalOpen: false
		}
	}

	videoPopupConfig = {
		titleBar: {
			enable: true
		},
		className: "videoPopup",
		//onComplete: () => {console.log("Hello")},
		onClosed: () => {this.stopVideo()} //this.stopVideo
	}

	openPopupVideo1 = (evt) => {
		evt.preventDefault();
		
		this.setState({modalOpen: true})
	}

	getPopupVideo = (poster, vidSrc) => {
		return (
			<Modal 
				show={this.state.modalOpen}
				onHide={this.closePopup}
				centered
				dialogClassName="videoPopup"
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="">
					
					<Player ref={player => {
						this.player = player
					}}
						videoId="video1"
						autoPlay={true}
						poster={poster}
					>
						<source src={vidSrc} type="video/mp4" />
					</Player>
				</Modal.Body>
			</Modal>
		)
	}

	closePopup = () => {
		this.stopVideo()
		this.setState({modalOpen: false})
	}

	/*openPopupVideo2= (evt) => {
		evt.preventDefault();
		
		const content = (
			//<ReactPlayer url='https://www.youtube.com/watch?v=bZSM5ZbdpWw' playing />
		)
		PopupboxManager.open({ content, config: this.videoPopupConfig });
	}*/

	stopVideo = () => {
		//console.log(this.player)
		if (this.player) {
			this.player.pause()
			//this.player = null
		}
	}

	render = () => {
		return <section id="making-of-wkeins-block" className="bg-transparent mx-n3">
			<Row className="p-3 bg-white" noGutters="true">
				<Col lg={9} md={12}>
					<h2 className="mb-2">Unsere Reise: „Making of …“ WKEINS</h2>
					<p>In unserem WKFokus Spezial haben wir Euch nochmal gezeigt, wie wir alle gemeinsam unser neues Hauptquartier gestaltet haben und was uns in WKEINS erwartet.</p>
				</Col>
			</Row>

			<Row noGutters="true" className="making-of-wkeins-bg mt-2">
				<Col>
					<Row style={{marginTop: "4em"}}>
						<Col xs={{span: 3, offset: 2}} className="bg-light-trans p-3">
							<a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwolterskluwer.sharepoint.com%2Fportals%2Fhub%2F_layouts%2F15%2FPointPublishing.aspx%3Fapp%3Dvideo%26p%3Dp%26chid%3Dda79cef6-3f0f-49f5-b923-fe1198417dc7%26vid%3D065a15b6-3f6f-48ac-bd0a-e75c2566063f%26from%3D2&data=02%7C01%7CFrederick.Assmuth%40wolterskluwer.com%7C8023c291be4e4db112d508d7da46873c%7C8ac76c91e7f141ffa89c3553b2da2c17%7C0%7C0%7C637243937833032371&sdata=NK%2BpyYc2cMt55T6%2FKiLVQc5HCyIjbsDDNx9pnJKClQ4%3D&reserved=0" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/WKFokus_Button.jpg`} className="img-fluid border mb-1" alt="" /></a>
							<p className="mb-0"><strong>„Making of …“ WKEINS:</strong><br/>Videoaufzeichnung des WKFokus WKEINS Spezial.</p>
						</Col>

						<Col xs={{span: 3, offset: 2}} className="bg-light-trans p-3">
						<a href="!#" onClick={this.openPopupVideo1}><img src={`${process.env.PUBLIC_URL}/images/WKEins_Impressionen_Button_v3.jpg`} className="img-fluid border mb-1" alt="" /></a>
								<p className="mb-0"><strong>Innenraum:</strong> Hier findet ihr das Video des fertigen WKEINS.</p>
						</Col>
					</Row>
					{this.getPopupVideo(`${process.env.PUBLIC_URL}/images/WKEins_Impressionen_Button_v2.jpg`, `${process.env.PUBLIC_URL}/video/WKEins_Impressionen_mit_Moebeln_Rundgang.mp4`)}
				</Col>
			</Row>

		</section>
	}
}

