import React from "react"
import GA from '../utilities/GoogleAnalytics'

let myCallback

const handleStepClick = evt => {
    const el = evt.target
    if (el.classList.contains("active") > -1) {
        //const step = parseInt(el.dataset.step) // does not work in IE <= 10
        const step = parseInt(el.getAttribute("data-step"))
        if (typeof myCallback === "function") {
            myCallback(step)
				}
				GA.logEvent("User", "Countdown Step", step)
    }
}

const CountdownButton = props => {
    myCallback = props.callback
    let classes = ""
    if (props.stepNumber === props.currentCountdownStep) {
        classes = "current"
    } else if (props.stepNumber < props.minCountdownStep) {
        classes = "disabled"
    } else {
        classes = "active"
    }
    return (
        <li
            className={classes}
            data-step={props.stepNumber}
            onClick={handleStepClick}
        >
            {props.stepNumber}
        </li>
    )
}

export default CountdownButton
