import React, {Component} from 'react'
import ReactDOM from 'react-dom'

import './svg-interactive.scss'

/* Note: it is important to remove the <title></title> element from the SVGs.
	Otherwise the may be shown as tooltip from the browser. 
*/

class SVGInteractiveComp extends Component {

	iconPrefix = "Icn_"
	groupPrefix = "Grp_"
	svg = null

	constructor(props) {
		super(props)
		this.roomInfoCb = props.roomInfoCb
		this.hideRoomInfoCb = props.hideRoomInfoCb
	}


	componentDidMount() {
		this.prepareSVG()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.prepareSVG()
	}

	prepareSVG = () => {
		const el = ReactDOM.findDOMNode(this)
		//console.log(el)
		if (el && el instanceof HTMLElement) {
			this.svg = el

			this.groups = this.svg.querySelectorAll('[id^=' + this.groupPrefix + ']')
			this.hideAllGroups()

			this.icons = this.svg.querySelectorAll('[id^=' + this.iconPrefix + ']')
			this.icons.forEach((theIcon) => {
				theIcon.addEventListener('mouseenter', this.handleMouseEnter)
				theIcon.addEventListener('mouseleave', this.handleMouseLeave)
				theIcon.classList.add('room-icon')
			})
		}
	}

	handleMouseEnter = (evt) => {
		this.roomInfoCb(evt.target.id)
	}

	handleMouseLeave = (evt) => {
		//console.log(evt.target.id)
		this.hideRoomInfoCb()
	}

	showGroupId = (theId) => {
		//console.log("showGroupId", theId)
		const theGrp = this.findGroupById(theId)
		if (theGrp) {
			theGrp.classList.remove('hidden')
		}
	}

	hideAllGroups = () => {
		this.groups.forEach((theGroup) => {
			theGroup.classList.add('hidden')
		})
	}

	findGroupById = grpId => {
		let theGrp = null
		for(let i=0; i < this.groups.length; i++) {
			if(this.groups[i].id === grpId) {
				theGrp = this.groups[i]
				break
			}
		}
		return theGrp
	}

	render() {
		//console.log(this.props.svgElement)
		return <div className="img-fluid interactive-element">
			{this.props.svgElement.render()}
		</div>
	}
}

const SVGInteractive = React.forwardRef((props, ref) => <SVGInteractiveComp {...props} ref={ref} />);
export default SVGInteractive

