import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Carousel} from 'react-responsive-carousel'

import '../scss/react-carousel.scss'

const MeetingCollaborationBlock = (props) => {
	return <section id="content-block" className="bg-transparent mx-n3">
		<Row className="p-3 bg-white" noGutters="true">
			<Col lg={9} md={12}>
				<h2 className="mb-0">Umgebaut: Projekt- und Meetingraum</h2>
				<p>Ab sofort ist unser Erkundungsraum als Projekt- und Meetingraum eingerichtet. Ihr findet hier einige Beispielmöbel, zu denen Ihr sehr gerne Feedback geben könnt. Zudem ist der Raum ab sofort auch über Outlook buchbar! Einfach nach „Erkundungsraum“ suchen.</p>
			</Col>
		</Row>

		<Row noGutters="true" className="mt-2">
			<Col>
				<Carousel showArrows={true} showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={false} interval={8000} className="lower-dots">
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_1.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_2.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_3.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_4.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_5.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_6.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_7.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_8.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_9.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_10.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_11.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_12.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_13.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_14.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_15.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_16.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/meeting_collaboration/meeting_collaboration_17.jpg`} alt="" />
				</Carousel>
			</Col>
		</Row>

	</section>
}

export default MeetingCollaborationBlock