import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Carousel} from 'react-responsive-carousel'

import '../scss/react-carousel.scss'


const MitentscheidenBlock = (props) => {
	return <section id="content-block" className="bg-transparent mx-n3">
		<Row className="p-3 bg-white" noGutters="true">
			<Col lg={9} md={12}>
				<h2 className="mb-0">Mitentscheiden!</h2>
			</Col>
		</Row>

		<Row noGutters="true" className="mt-2">
			<Col>
				<Carousel showArrows={true} showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={false} interval={8000} >
					{/*<img src={`${process.env.PUBLIC_URL}/images/voting/01_Voting-Prozess.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/voting/02_Stuhlvorstellung_Eins.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/voting/03_Stuhlvorstellung_Zwei.jpg`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/voting/04_Stuhlvorstellung_Drei.jpg`} alt="" />*/}
					<img src={`${process.env.PUBLIC_URL}/images/voting/Mitentscheiden_Stuhl_EINS_1.png`} alt="" />
					<img src={`${process.env.PUBLIC_URL}/images/voting/Mitentscheiden_Stuhl_EINS_2.jpg`} alt="" />
				</Carousel>
			</Col>
		</Row>

	</section>
}

export default MitentscheidenBlock