import React from 'react'
import Container from 'react-bootstrap/Container'
import MeetingCollaborationBlock from '../components/meeting_collaboration-block'
import FooterBlock from '../components/footer-block'


function MitentscheidenPage() {
	return (
		<Container>
			<MeetingCollaborationBlock />
			<FooterBlock />
		</Container>
	);
}

export default MitentscheidenPage
