const RoomInfos = {
	Level0: {
		data: [
			{
				id: "Icn_000-Post",
				farbe: "grau",
				icon: "Post",
				raum: "0.24",
				titel: "Poststelle",
				personen: "X",
				buchung: "fest besetzt",
				text: "Hier kommt Deine Post an und alles was Du versenden möchtest, gibst Du hier ab."
			},

			{
				id: "Icn_001-Rezeption",
				farbe: "rot",
				icon: "Rezeption",
				raum: "0.01",
				titel: "Empfang",
				personen: "2",
				buchung: "fest besetzt",
				text: "Willkommen in unserem Hauptquartier WKEINS. Besucher empfangen wir hier mit offen Armen. Erwartest Du Besuch, melde Deine Gäste bitte vorher an. Sie erhalten dann ihren Besucherausweis."
			},

			{
				id: "Icn_002-Wartebereich",
				farbe: "rot",
				icon: "Wartebereich",
				raum: "0.01",
				titel: "Wartebereich",
				text: "Hier können Deine Gäste einen Kaffee trinken, während sie auf Dich warten."
			},

			{
				id: "Icn_003-WC",
				farbe: "grau",
				icon: "WC",
				raum: "0.43-0.45",
				titel: "WC",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_003_1-Garderobe",
				farbe: "grau",
				icon: "Garderobe",
				raum: "0.50",
				titel: "Gäste Garderobe",
				text: "Hier können Besucher ihre Jacken und Gepäck deponieren."
			},

			{
				id: "Icn_004-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "0.49",
				titel: "Teeküche Catering",
				personen: "X",
				buchung: "X",
				text: "Dieser Raum wird für die Bewirtung der Konferenzräume genutzt."
			},

			{
				id: "Icn_005-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "0.48",
				titel: "Snack Automaten",
				personen: "X",
				buchung: "X",
				text: "Hast Du  Hunger oder Lust auf etwas Süßes? Hier findest Du kleine Mahlzeiten unseres Partners Hello Fresh und Automaten mit Snacks und Getränken."
			},

			{
				id: "Icn_006-KonferenzraumS",
				farbe: "rot",
				icon: "KonferenzraumS",
				raum: "0.47",
				titel: "Konferenzraum S",
				personen: "8",
				buchung: "buchbar",
				text: "Alles was du für ein Meeting mit Kunden oder mehreren Kollegen benötigst, findest du hier. Screens und Whiteboards unterstützen dich bei deiner Präsentation"
			},

			{
				id: "Icn_007-KonferenzraumM",
				farbe: "rot",
				icon: "KonferenzraumM",
				raum: "0.46",
				titel: "Konferenzraum M",
				personen: "10",
				buchung: "buchbar",
				text: "Du kannst die Räume 0.46 und 0.41 zu einem großen Konfer&shy;enzraum umbauen. Alles was du für ein Meeting mit Kunden oder mehreren Kollegen benötigst, findest du hier. Screens und Whiteboards unterstützen dich bei deiner Präsentation."
			},

			{
				id: "Icn_008-KonferenzraumL",
				farbe: "rot",
				icon: "KonferenzraumL",
				raum: "0.41",
				titel: "Konferenzraum L",
				personen: "17",
				buchung: "buchbar",
				text: "Du kannst die Räume 0.46 und 0.41 zu einem großen Konfer&shy;enzraum umbauen. Alles was du für ein Meeting mit Kunden oder mehreren Kollegen benötigst, findest du hier."
			},

			{
				id: "Icn_009-Lounge",
				farbe: "orange",
				icon: "Lounge",
				raum: "0.42",
				titel: "Gäste-Lounge",
				personen: "25",
				buchung: "nicht buchbar",
				text: "Hier werden Seminarteilnehmer bewirtet."
			},

			{
				id: "Icn_010-KonferenzraumS",
				farbe: "rot",
				icon: "KonferenzS",
				raum: "0.38",
				titel: "Konferenz S",
				personen: "8",
				buchung: "buchbar",
				text: "Alles was du für ein Meeting mit Kunden oder mehreren Kollegen benötigst, findest du hier. Screens und Whiteboards unterstützen dich bei deiner Präsentation."
			},

			{
				id: "Icn_011-Trainingsraum",
				farbe: "rot",
				icon: "Trainingsraum",
				raum: "0.39",
				titel: "Seminarraum 2",
				personen: "12",
				buchung: "buchbar",
				text: "Hier finden Workshops und Kundentrainings statt. Viele Plätze sind mit PCs ausgestattet, an denen unsere Kunden unsere Softwarelösungen kennenlernen können."
			},

			{
				id: "Icn_012-Innenhof",
				farbe: "rot",
				icon: "Innenhof",
				raum: "0.00",
				titel: "Innenhof",
				personen: "X",
				buchung: "X",
				text: "Kurz mal an die frische Luft? Das geht in unserem Innenhof. Bitte achte auf die Lautstärke, um andere nicht zu stören. Einen Raucherbereich gibt es übrigens bei den Parkplätzen."
			},

			{
				id: "Icn_013-Trainingsraum",
				farbe: "rot",
				icon: "Trainingsraum",
				raum: "0.36",
				titel: "Seminarraum 1",
				personen: "22",
				buchung: "buchbar",
				text: "Hier finden Workshops und Kundentrainings statt. Viele Plätze sind mit PCs ausgestattet, an denen unsere Kunden unsere Softwarelösungen kennenlernen können."
			},

			{
				id: "Icn_014-WC",
				farbe: "grau",
				icon: "WC",
				raum: "0.34-0.35",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_015-KonferenzraumM",
				farbe: "rot",
				icon: "KonferenzraumM",
				raum: "0.33",
				titel: "Cisco Konferenzraum",
				personen: "10",
				buchung: "buchbar",
				text: "Hier können wir mit bis zu 10 Personen Webkonferenzen abhalten. Das Cisco Webkonferenz-System bietet in diesem Raum beste Qualität und höchsten technischen Komfort, besonders bei Webkonferenzen mit unseren internationalen Kolleginnen und Kollegen."
			},

			{
				id: "Icn_016-Ruheraum",
				farbe: "grau",
				icon: "Ruheraum",
				raum: "0.32",
				titel: "Ruheraum",
				personen: "X",
				buchung: "für dich gebucht",
				text: "Musst Du Dich kurz hinlegen oder benötigst Du kurz einen Raum für Dich, findest Du hier die Möglichkeit dazu. Im Zweifel wende Dich aber bitte an einen unserer Ersthelfer oder direkt an einen Arzt."
			},

			{
				id: "Icn_017-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "0.17",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},

			{
				id: "Icn_018-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "0.20",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_018_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},

			{
				id: "Icn_019-StandUp",
				farbe: "grün",
				icon: "Stand-Up",
				raum: "0.22",
				titel: "Stand-Up Meeting",
				personen: "4",
				buchung: "nicht buchbar",
				text: "Kurze Besprechungen im Team können hier stattfinden. Bitte achtet aber darauf, dass Ihr niemanden stört und keine vertraulichen Dinge besprecht."
			},

			{
				id: "Icn_020-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "0.21",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_021-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "0.19",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für unser Miteinander. Hier findest Du auch Kühlschränke und eine Kaffeemaschine. Kleine Snacks kannst Du auch hier verzehren, für warmes Essen gibt es unsere Lounge im 4. OG. Bitte tragt alle dazu bei, dass es hier immer sauber und ordentlich ist, damit sich alle wohl fühlen."
			},

			{
				id: "Icn_022-ITlab",
				farbe: "grau",
				icon: "ITlab",
				raum: "0.18",
				titel: "IT-Lab",
				personen: "X",
				buchung: "X",
				text: "Hier testet GBS neue Hard- und Software für uns."
			},

			{
				id: "Icn_023-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "0.16",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_024-Kinderbuero",
				farbe: "blau",
				icon: "Kinderbuero",
				raum: "0.15",
				titel: "Eltern-Kind-Büro",
				personen: "1",
				buchung: "buchbar",
				text: "Du hast kurzfristig ein Betreuungsproblem oder möchtest Deinem Kind einfach so mal WKEINS zeigen. Reserviere Dir das Eltern-Kind-Büro. Hier gibt es eine Leseecke und genug Platz, damit Dein Kind in Ruhe mit seinem Spielzeug spielen kann, während Du arbeitest."
			},

			{
				id: "Icn_025-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "0.04",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen. "
			},

			{
				id: "Icn_026_1-TechBar",
				farbe: "grau",
				icon: "Techbar",
				raum: "0.14",
				titel: "GBS Tech Bar",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Du brauchst einen schnellen IT-Tipp? Du brauchst neue Hardware? An der Tech-Bar helfen Dir die GBS-Kollegen schnell und unkompliziert weiter."
			},

			{
				id: "Icn_026_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},

			{
				id: "Icn_026-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "0.14",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_027-WC",
				farbe: "grau",
				icon: "WC",
				raum: "0.10-0.11",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_028-Dusche",
				farbe: "grau",
				icon: "Dusche",
				raum: "0.05",
				titel: "Dusche",
				personen: "X",
				buchung: "X",
				text: "Mit dem Fahrrad zur Arbeit? Joggen nach Feierabend? Die Duschen stehen Dir zur Verfügung, um Dich wieder frisch zu machen. Deponiere Deine nassen Handtücher bis zum Feierabend im belüfteten Spint und sorge dafür, dass die Dusche nach der Nutzung wieder sauber ist."
			},

			{
				id: "Icn_029-Bibliothek",
				farbe: "rot",
				icon: "Bibliothek",
				raum: "0.00",
				titel: "Bibliothek",
				personen: "8",
				buchung: "buchbar",
				text: "Ein Ort für unsere Lösungen. Du willst neue oder langjährige Kunden oder Autoren empfangen und Ihnen zeigen, wer wir sind und was wir können? Dann triff Dich doch in unserer Bibliothek mit ihnen. Ein Screen steht ebenfalls für Präsentationen zur Verfügung. An speziellen Terminals kann unsere Software ausprobiert werden. "
			},

			{
				id: "Icn_030-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "0.07",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			}
		]
	},

	Level1: {
		data: [
			{
				id: "Icn_031-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "1.41",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},

			{
				id: "Icn_032-WC",
				farbe: "grau",
				icon: "WC",
				raum: "1.38-1.39",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_034-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "1.33",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},

			{
				id: "Icn_034_1-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.33",
				titel: "Telefonbox stand Up",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},

			{
				id: "Icn_035-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "1.37",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für das Miteinander in deinem Team. Hier findest du Kühlschränke für dein mitgebrachtes Essen und Geschirr welches du benutzen kannst. Sorge dafür, dass es immer sauber und ordentlich ist und auch deine Mitmenschen sich auch wohl fühlen."
			},

			{
				id: "Icn_036-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "1.36",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_037-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.35",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_037_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},

			{
				id: "Icn_038-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "1.32",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},

			{
				id: "Icn_039-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "1.31",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},


			{
				id: "Icn_041-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "1.22",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},

			{
				id: "Icn_042-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.27",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_043-WC",
				farbe: "grau",
				icon: "WC",
				raum: "1.28-1.29",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_043_1-Drucker_1",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke."
			},

			{
				id: "Icn_046-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "1.19",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_047-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "1.22",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},

			{
				id: "Icn_047_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke."
			},

			{
				id: "Icn_048-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.17-1.18",
				titel: "Telefonbox klein",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},

			{
				id: "Icn_049-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "1.16",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für das Miteinander in deinem Team. Hier findest du Kühlschränke für dein mitgebrachtes Essen und Geschirr welches du benutzen kannst. Sorge dafür, dass es immer sauber und ordentlich ist und auch deine Mitmenschen sich auch wohl fühlen."
			},

			{
				id: "Icn_050-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "1.15",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_051-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "1.14",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},

			{
				id: "Icn_052-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.11",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_053-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "1.09",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_053_1-Drucker_1",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke."
			},

			{
				id: "Icn_054-WC",
				farbe: "grau",
				icon: "WC",
				raum: "1.07-1.08",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_055-Dusche",
				farbe: "grau",
				icon: "Dusche",
				raum: "1.03-1.04",
				titel: "Dusche",
				personen: "X",
				buchung: "X",
				text: "Mit dem Fahrrad zur Arbeit? Joggen nach Feierabend? Die Duschen stehen Dir zur Verfügung, um Dich wieder frisch zu machen. Deponiere Deine nassen Handtücher bis zum Feierabend im belüfteten Spint und sorge dafür, dass die Dusche nach der Nutzung wieder sauber ist."
			},

			{
				id: "Icn_056-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.02",
				titel: "Telefonbox stand up",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},

			{
				id: "Icn_057-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "1.02",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			}
		]
	},

	Level2: {
		data: [
			{
				id: "Icn_058-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "2.43",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Du kannst die Räume 2.43 und 2.42 zu einem großen Raum umbauen. Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster.  Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},
			{
				id: "Icn_059-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.42",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Du kannst die Räume 2.43 und 2.42 zu einem großen Raum umbauen. Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_060-WC",
				farbe: "grau",
				icon: "WC",
				raum: "2.39-2.40",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_060_1-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "1.33",
				titel: "Telefonbox stand up",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},

			{
				id: "Icn_060_2-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},
			{
				id: "Icn_061-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "2.34",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_062-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "2.37-2.38",
				titel: "Telefonbox klein",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},
			{
				id: "Icn_063-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "2.36",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für das Miteinander in deinem Team. Hier findest du Kühlschränke für dein mitgebrachtes Essen und Geschirr welches du benutzen kannst. Sorge dafür, dass es immer sauber und ordentlich ist und auch deine Mitmenschen sich auch wohl fühlen."
			},
			{
				id: "Icn_064-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.35",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_065-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "2.33",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},
			{
				id: "Icn_066-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.32",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_067-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "2.28",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konferenzen, Teams-Calls oder Webinare sind hier mit bis zu zwei Personen möglich."
			},

			{
				id: "Icn_067_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},

			{
				id: "Icn_068-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "2.22",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_069-WC",
				farbe: "grau",
				icon: "WC",
				raum: "2.29-2.30",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},

			{
				id: "Icn_072-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "2.29",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "nicht buchbar",
				text: "Telefongespräche, Telefon&shy;konfer&shy;enzen, Skype-Calls oder Webinare sind hier mit bis zu zwei Personen möglich. Sobald der Raum frei ist, kannst du ihn nutzen."
			},

			{
				id: "Icn_072_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},

			{
				id: "Icn_073-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.18",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_074-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "2.17",
				titel: "Telefonbox klein",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},
			{
				id: "Icn_075-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "2.12",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_076-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "2.15",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für das Miteinander in deinem Team. Hier findest du Kühlschränke für dein mitgebrachtes Essen und Geschirr welches du benutzen kannst. Sorge dafür, dass es immer sauber und ordentlich ist und auch deine Mitmenschen sich auch wohl fühlen."
			},
			{
				id: "Icn_077-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.13-2.14",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_078-Bibliothek",
				farbe: "rot",
				icon: "Bibliothek",
				raum: "2.11",
				titel: "Präsenzbibliothek",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du viele aktuelle Ausgaben unserer Werke. Benötigst Du ein Werk für Deine Arbeit, kannst du es hier finden. Bitte bringe es auch wieder zurück, wenn Du mit Deiner Arbeit fertig bist."
			},

			{
				id: "Icn_080-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "2.08-2.09",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "buchbar",
				text: "Telefongespräche, Telefon&shy;konfer&shy;enzen, Skype-Calls oder Webinare sind hier mit bis zu zwei Personen möglich. Sobald der Raum frei ist, kannst du ihn nutzen."
			},
			{
				id: "Icn_081-WC",
				farbe: "grau",
				icon: "WC",
				raum: "2.06-2.07",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},
			{
				id: "Icn_082-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "2.02",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_083-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "2.05",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},

			{
				id: "Icn_083_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke."
			}
		]
	},

	Level3: {
		data: [
			{
				id: "Icn_085-AgilerProjektraum",
				farbe: "grün",
				icon: "AgilerProjektraum",
				raum: "3.38",
				titel: "Agiler Projektraum",
				personen: "12",
				buchung: "buchbar",
				text: "Ihr wollt in größeren Gruppen an einem Thema arbeiten und dies über einen längeren Zeitraum? Nutzt die Projekträume und bucht sie für das benötigte Zeitfenster. Screen und Whiteboard bieten Dir Kommunikationsflächen. Ist Dein Team fertig, sorge bitte dafür, dass der Projektraum auch für andere nutzbar bleibt. Wir haben übrigens insgesamt 7 Stück."
			},
			{
				id: "Icn_086-WC",
				farbe: "grau",
				icon: "WC",
				raum: "3.33-3-34",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},
			{
				id: "Icn_086_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},
			{
				id: "Icn_087-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "3.30",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_088-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "3.32",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Ein Raum für das Miteinander in deinem Team. Hier findest du Kühlschränke für dein mitgebrachtes Essen und Geschirr welches du benutzen kannst. Sorge dafür, dass es immer sauber und ordentlich ist und auch deine Mitmenschen sich auch wohl fühlen."
			},
			{
				id: "Icn_089-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.31",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_090-Terrasse",
				farbe: "orange",
				icon: "Terrasse",
				raum: "X",
				titel: "Terrasse",
				personen: "1-20",
				buchung: "nicht buchbar",
				text: "Hier kannst Du Deinen Kaffee an der frischen Luft genießen. Einen Raucherbereich gibt es übrigens bei den Parkplätzen."
			},
			{
				id: "Icn_091-WC",
				farbe: "grau",
				icon: "WC",
				raum: "3.28-3.29",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},
			{
				id: "Icn_092-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.25",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_093-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.26",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_094-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.27",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_095-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "3.20",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen. "
			},
			{
				id: "Icn_095_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},
			{
				id: "Icn_097-Teekueche",
				farbe: "orange",
				icon: "Teekueche",
				raum: "3.18",
				titel: "Teeküche",
				personen: "6",
				buchung: "nicht buchbar",
				text: "Für Veranstaltungen oder die Bewirtung der Konferenz&shy;räume wird dieser Raum genutzt. Du findest deine Teeküche in der Nähe deines Sitzplatzes."
			},
			{
				id: "Icn_098-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "3.11",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen. "
			},
			{
				id: "Icn_099-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.15-3.16",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_099_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},
			{
				id: "Icn_100-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "3.13-3.14",
				titel: "Telefonbox groß",
				personen: "2",
				buchung: "buchbar",
				text: "Telefongespräche, Telefon&shy;konfer&shy;enzen, Skype-Calls oder Webinare sind hier mit bis zu zwei Personen möglich. Sobald der Raum frei ist, kannst du ihn nutzen."
			},
			{
				id: "Icn_101-Terrasse",
				farbe: "orange",
				icon: "Terrasse",
				raum: "X",
				titel: "Terrasse",
				personen: "1-20",
				buchung: "nicht buchbar",
				text: "Hier kannst Du Deinen Kaffee an der frischen Luft genießen. Einen Raucherbereich gibt es übrigens bei den Parkplätzen."
			},
			{
				id: "Icn_101-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.12",
				titel: "Meeting",
				personen: "4",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 4 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen."
			},
			{
				id: "Icn_102-Arbeitsplatz",
				farbe: "blau",
				icon: "Arbeitsplatz",
				raum: "3.02",
				titel: "Dein Arbeitsplatz",
				personen: "X",
				buchung: "für dich gebucht",
				text: "… gehört nur Dir. Deine persönlichen Dinge kannst du in deinem Personal Pixel verstauen, Arbeitsunterlagen finden in den Schränken Platz. Dein Schreibtisch lässt sich hoch- und runterfahren, und natürlich kannst Du hier auch gerne ein Bild oder andere Erinnerungsstücke aufstellen."
			},
			{
				id: "Icn_103-WC",
				farbe: "grau",
				icon: "WC",
				raum: "3.08-3.09",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},
			{
				id: "Icn_104-MeetingKlein",
				farbe: "grün",
				icon: "MeetingKlein",
				raum: "3.07",
				titel: "Meeting",
				personen: "2",
				buchung: "buchbar",
				text: "Hier ist Platz für Meetings mit bis zu 2 Personen. Screen und Whiteboard bieten Dir Kommunikationsflächen. "
			},
			{
				id: "Icn_105-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "3.06",
				titel: "Telefonbox klein",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},
			{
				id: "Icn_105_1-Fokus",
				farbe: "blau",
				icon: "Fokus",
				raum: "0.00",
				titel: "Telefonbox Stand Up",
				personen: "1",
				buchung: "nicht buchbar",
				text: "Für spontane oder vertrauliche Telefonate stehen die kleinen Telefonboxen zur Verfügung und sorgen dank Schallschutz für Diskretion. Du hast hier auch alle Anschlüsse, um Deinen Laptop mitzunehmen."
			},



			{
				id: "Icn_106_1-Drucker",
				farbe: "grau",
				icon: "Drucker",
				raum: "0.00",
				titel: "Drucker",
				personen: "X",
				buchung: "X",
				text: "Hier findest Du einen Drucker und Deine Ausdrucke. "
			},
		]
	},

	Level4: {
		data: [
			{
				id: "Icn_108-WC",
				farbe: "grau",
				icon: "WC",
				raum: "4.11-4.12",
				titel: "WC",
				personen: "X",
				buchung: "X",
				text: "Hier findest du die Toiletten."
			},
			{
				id: "Icn_109-Lounge",
				farbe: "orange",
				icon: "Lounge",
				raum: "4.16",
				titel: "Lounge",
				personen: "1-199",
				buchung: "buchbar",
				text: "Hier ist genug Platz für gemeinsame Mittagspausen oder kurze Gespräche bei einem Kaffee. Im Küchenbereich stehen ausreichend Mikrowellen und Geschirr zur Verfügung, um Dir auch warme Speisen zuzubereiten. Benutztes Geschirr räumst Du einfach in den Geschirrspüler. In der Lounge finden auch unsere Veranstaltungen statt."
			},
			{
				id: "Icn_110-Terrasse",
				farbe: "orange",
				icon: "Terrasse",
				raum: "X",
				titel: "Dachterrasse",
				personen: "1-199",
				buchung: "nicht buchbar",
				text: "Domblick in der Mittagspause hast Du von der Dachterrasse aus. Hier kannst Du Dein Mittagessen oder Deinen Kaffee an der frischen Luft genießen. Einen Raucherbereich gibt es übrigens bei den Parkplätzen."
			}
		]
	},
}

const GroupInfos = {
	Level0: {
		title: "Teams EG",
		data: [
			{
				id: "Grp_0.1Team",
				name: "Tax & Accounting"
			},
			{
				id: "Grp_0.2Team",
				name: "Operational Procurement"
			},
			{
				id: "Grp_0.3Team",
				name: "Real Estate & Facility Management"
			},

			{
				id: "Grp_0.5Team",
				name: "GBS/IT"
			}
		]
	},

	Level1: {
		title: "Teams Etage 1",
		data: [
			{
				id: "Grp_1.1Team",
				name: "Regulatory: CIS + InCompany"
			},

			{
				id: "Grp_1.2Team",
				name: "Marketing & Sales Services & Infrastructure"
			},

			{
				id: "Grp_1.3Team",
				name: "LSW: Inside Sales"
			},

			{
				id: "Grp_1.4Team",
				name: "LSW: Technical Services"
			},

			{
				id: "Grp_1.5Team",
				name: "LSW: Dokumentation + QS"
			},

			{
				id: "Grp_1.6Team",
				name: "LSW: Developement (GPO)"
			},

			{
				id: "Grp_1.7Team",
				name: "LSW: Product Management"
			},

			{
				id: "Grp_1.8Team",
				name: "LSW: Management & Assistenz"
			},

			{
				id: "Grp_1.9Team",
				name: "LSW: Segment PMS, CLG"
			},

			{
				id: "Grp_1.10Team",
				name: "LSW: Marketing"
			},

			{
				id: "Grp_1.11Team",
				name: "LSW: Customer Success Management"
			},

			{
				id: "Grp_1.12Team",
				name: "LSW: Helpdesk"
			}
		]
	},

	Level2: {
		title: "Teams Etage 2",
		data: [
			{
				id: "Grp_2.1Team",
				name: "Regulatory: Schule"
			},

			{
				id: "Grp_2.2Team",
				name: "Regulatory: Kita"
			},

			{
				id: "Grp_2.3Team",
				name: "Legal: Marketplace & LTO"
			},

			{
				id: "Grp_2.5Team",
				name: "GPO"
			},


			{
				id: "Grp_2.6Team",
				name: "Legal: Innovation/UX"
			},

			{
				id: "Grp_2.7Team",
				name: "Legal: Platform Management"
			},

			{
				id: "Grp_2.8Team",
				name: "Legal: Legal Digital"
			},

			{
				id: "Grp_2.9Team",
				name: "Finance"
			},

			{
				id: "Grp_2.10Team",
				name: "Legal: Public Digital"
			},

			{
				id: "Grp_2.11Team",
				name: "Operations"
			},

			{
				id: "Grp_2.12Team",
				name: "Legal: Content Strategy"
			},

			{
				id: "Grp_2.13Team",
				name: "Legal: Content Public Verticals & LL"
			},
			{
				id: "Grp_2.14Team",
				name: "Legal: Content Horizontal/CVD"
			},

			{
				id: "Grp_2.15Team",
				name: "Legal: Content Verticals 1"
			},

			{
				id: "Grp_2.16Team",
				name: "Legal: Content Verticals 2"
			}
		]
	},

	Level3: {
		title: "Teams Etage 3",
		data: [
			{
				id: "Grp_3.3Team",
				name: "Legal: Marketing "
			},

			{
				id: "Grp_3.2Team",
				name: "Legal: Field Sales"
			},

			{
				id: "Grp_3.1Team",
				name: "Legal: Media Sales"
			},

			{
				id: "Grp_3.4Team",
				name: "Legal: Marketing"
			},

			{
				id: "Grp_3.5Team",
				name: "Legal: Handelsvertrieb"
			},

			{
				id: "Grp_3.6Team",
				name: "Legal: Customer Service + Inside Sales"
			},

			{
				id: "Grp_3.7Team",
				name: "Management Team & Assistenz"
			},

			{
				id: "Grp_3.10Team",
				name: "Finance & Accounting"
			},

			{
				id: "Grp_3.9Team",
				name: "Communications & Brand"
			},

			{
				id: "Grp_3.11Team",
				name: "Legal & Compliance"
			},

			{
				id: "Grp_3.11Team",
				name: "Strategy"
			},

			{
				id: "Grp_3.12Team",
				name: "Human Resources "
			}
		]
	},
	Level4: {
		title: "Teams Etage 4"
	}

}

export { RoomInfos, GroupInfos }
