import React from "react";
import GA from "../utilities/GoogleAnalytics";

let myCallback;

const handleBtnClick = (evt) => {
	const el = evt.target;
	if (!el.classList.contains("active") > -1) {
		//const floor = parseInt(el.dataset.floor) // does not work in IE <= 10
		const floor = parseInt(el.getAttribute("data-floor"));
		if (typeof myCallback === "function") {
			myCallback(floor);
		}
		GA.logEvent("User", "Floorselector", floor);
	}
};

const FloorselectorButton = (props) => {
	myCallback = props.callback;
	let classes = "";
	if (props.floorNum === props.currentFloor) {
		classes = "active";
	}
	return (
		<li
			className={classes}
			data-floor={props.floorNum}
			onClick={handleBtnClick}
		>
			{props.caption}
		</li>
	);
};

export default FloorselectorButton;
