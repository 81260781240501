import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import CountdownButtons from './countdown-buttons'
import ButtonWithIcon from './button-with-icon'
import { Player } from 'video-react'
import 'video-react/styles/scss/video-react.scss'
//import GlobalContext from '../utilities/GlobalContext'
//import Popup from "reactjs-popup"
//import ReactPlayer from 'react-player'

class CountdownBlock extends Component {

	constructor () {
		super()
		this.state = {
			minCountdownStep: 3,
			currentCountdownStep: 3,
			weeksLeftToMove: this.calcWeeksToGo(),
			modalOpen: false
		}
	}

	calcWeeksToGo () {
		const oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
		const moveDate = new Date(2020,4,31); // first monday in June 2020 (last day of May); month index range is from 0 to 11
		const todayDate = new Date();

		const diffDays = Math.round(Math.abs((moveDate.getTime() - todayDate.getTime())/(oneDay)));
		const weeks = Math.max(Math.floor(diffDays / 7), 0) // clamp to 0 … just in case
		return weeks
	}

	stepChange = (newStep) => {
		if (newStep >= this.state.minCountdownStep) {
			this.setState({currentCountdownStep: newStep})
		}
	}

	countdownTitleboxContent() {
		let boxContent = ""
		switch(this.state.currentCountdownStep) {
			case 3:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9} className="mt-3">
						<div className="countdown-date">7.5.2020</div>
						<h2>Teamverortung</h2>
					</Col>
				</Row>
				break

			case 4:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">5.5.2020</div>
						<h2>WKFokus<br/>„WKEINS Spezial“</h2>
					</Col>
				</Row>
				break

			case 5:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9} className="mt-3">
						<div className="countdown-date">7.4.2020</div>
						<h2>Telefonboxen</h2>
					</Col>
				</Row>
				break

			case 6:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">9.12.2019</div>
						<h2>Projekt- und Meetingraum</h2>
					</Col>
				</Row>
				break

			case 7:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">8.10.2019</div>
						<h2>Unser Bergfest</h2>
					</Col>
				</Row>
				break

			case 8:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">2.9.2019</div>
						<h2>Ideen gesucht<br/>&nbsp;</h2>
					</Col>
				</Row>
				break

			case 9:
				boxContent = <Row className="align-items-center">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">6.8.2019</div>
						<h2>Launch der WKEins Microsite</h2>
					</Col>
				</Row>
				break

			default:
				boxContent = <Row className="align-items-center clearfix">
					<Col xs={4} lg={3}>
						<div className="countdown-step-large text-center">{this.state.currentCountdownStep}</div>
					</Col>
					<Col xs={8} lg={9}>
						<div className="countdown-date">26.7.2019</div>
						<h2>Eröffnung des Erkundungs&shy;raums</h2>
					</Col>
				</Row>
				break
		}
		return boxContent
	}

	countdownInfoContent() {
		let infoContent = ""
		switch(this.state.currentCountdownStep) {
			case 3:
				infoContent = <div className="countdown-info-box">
					<h2>Dein Arbeitsplatz: Wir zeigen dir, wo du und dein Team sitzen.</h2>
					<p className="mb-3">
					Alle Etagen, alle Teams und alle Räume kennenlernen.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/floorplan">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break

			case 4:
				infoContent = <div className="countdown-info-box">
					<h2>Unsere Reise: „Making of …“ WKEINS</h2>
					<p className="mb-3">
					In unserem WKFokus Spezial haben wir Euch nochmal gezeigt, wie wir alle gemeinsam unser neues Hauptquartier gestaltet haben und was uns in WKEINS erwartet.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/making_of_wkeins">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break

			case 5:
				infoContent = <div className="countdown-info-box">
					<h2>Ihr habt entschieden: Telefonbox #2 zieht mit uns ein!</h2>
					<p className="mb-3">
					In WKEINS findet Ihr jede Menge Rückzugsorte. Neben den Meetingräumen und fest installierten Telefonboxen gibt es auch sechs zusätzliche Telefonboxen.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/telefoneboxes">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break

			case 6:
				infoContent = <div className="countdown-info-box">
					<h2>Umgebaut: im Erkundungsraum hat sich was getan</h2>
					<p className="mb-3">
					Ab sofort könnt Ihr in unserem Erkundungsraum ein Setup für Projekte und Meetings erkunden. Natürlich ist auch wieder Euer Feedback gefragt.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/meeting_collaboration">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break

				case 7:
				infoContent = 
				<div className="countdown-info-box">
					<h2>Reingeschaut: Auf unserem Bergfest haben wir Euch mit in den Rohbau genommen. Die Videos gibt es hier.</h2>		
				</div>
				break

			case 8:
				infoContent = <div className="countdown-info-box">
					<h2>Mitmachen: Wie sollen unsere Lounge und die Bibliothek gestaltet sein?</h2>
					<p className="mb-3">
					Wir stellen Teams zusammen, die gemeinsam mit unseren Innenarchitekten die Gestaltung der Lounge und der Bibliothek erarbeiten.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/lounge_bibliothek">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break

			case 9:
				infoContent = <div className="countdown-info-box">
					<h2>Erreichbar: Unsere WKEins Microsite ist live!</h2>
					<p className="mb-3">
					Hier findet Ihr ab sofort aktuelle Infos rund um WKEins und unser neues Hauptquartier, es gibt ständig Neues zu entdecken.
					</p>
				</div>
				break

			default:
				infoContent = <div className="countdown-info-box">
					<h2>Eröffnet: Erste Einblicke in das Büro-Design</h2>
					<p className="mb-3">
					Mit einer kleinen Feier bei „Australian Pies“ und Currywurst haben wir unseren Erkundungsraum eröffnet. Hier können wir schon mal erkunden, was uns in der <nobr>Wolters-Kluwer-Straße 1</nobr> erwarten wird.
					</p>
					<ButtonWithIcon atBottomRight extraClass="theme-3" linkTarget="/einblicke">
						Mehr erfahren
					</ButtonWithIcon>
				</div>
				break
		}

		return infoContent
	}

	render () {
		//const stepImgClass = "step-" + this.state.currentCountdownStep
		return (
			<React.Fragment>
				<section id="content-block" className="mx-n3">
					<section id="countdown-block">
						<Row className="countdown-head p-3" noGutters="true">
							<Col lg={10} xs={9}>
								<div className="wk-eins-logo float-left"></div>
								<h2 className="mt-4">Unser Countdown bis zum Umzug</h2>
								<div className="clearfix"></div>
								<CountdownButtons numberOfSteps={10} currentCountdownStep={this.state.currentCountdownStep} minCountdownStep={this.state.minCountdownStep} callback={this.stepChange} />
							</Col>
							<Col lg={2} xs={3}>
								<h2>Wochen bis zum Umzug:</h2>
								<div className="weeks-counter-large">
									{this.state.weeksLeftToMove}
								</div>
							</Col>
						</Row>
						<CountdownImageContent currentCountdownStep = {this.state.currentCountdownStep}>
							<Row className="position-bottom">
								<Col xs={5} className="d-flex align-items-stretch">
									<div className="countdown-title-box">
										{this.countdownTitleboxContent()}
									</div>
								</Col>
								<Col xs={7}>
									{this.countdownInfoContent()}
								</Col>
							</Row>
						</CountdownImageContent>
					</section>
				</section>
			</React.Fragment>
		)
	}
}


class CountdownImageContent extends Component {

	constructor (props) {
		super(props)
		this.state = {
			modalOpen: false
		}
	}

	videoPopupConfig = {
		titleBar: {
			enable: true
		},
		className: "videoPopup",
		//onComplete: () => {console.log("Hello")},
		onClosed: () => {this.stopVideo()} //this.stopVideo
	}

	openPopupVideo1 = (evt) => {
		evt.preventDefault();
		
		this.setState({modalOpen: true})
	}

	getPopupVideo = (poster, vidSrc) => {
		return (
			<Modal 
				show={this.state.modalOpen}
				onHide={this.closePopup}
				centered
				dialogClassName="videoPopup"
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="">
					
					<Player ref={player => {
						this.player = player
					}}
						videoId="video1"
						autoPlay={true}
						poster={poster}
					>
						<source src={vidSrc} type="video/mp4" />
					</Player>
				</Modal.Body>
			</Modal>
		)
	}

	closePopup = () => {
		this.stopVideo()
		this.setState({modalOpen: false})
	}

	/*openPopupVideo2= (evt) => {
		evt.preventDefault();
		
		const content = (
			//<ReactPlayer url='https://www.youtube.com/watch?v=bZSM5ZbdpWw' playing />
		)
		PopupboxManager.open({ content, config: this.videoPopupConfig });
	}*/

	stopVideo = () => {
		//console.log(this.player)
		if (this.player) {
			this.player.pause()
			//this.player = null
		}
	}


	render() {
		const stepImgClass = "step-" + this.props.currentCountdownStep
		let ciContent
		switch(this.props.currentCountdownStep) {
			/*case 4:
				ciContent = 
					<React.Fragment>
						<div className={"countdown-image-block " + stepImgClass}>
							<div className="content-abs-pos">
								<Row style={{marginTop: "2em"}} className="d-flex justify-content-center">
									<Col className="video-button-large bg-light-trans p-3">
										<a href="!#" onClick={this.openPopupVideo1}><img src={`${process.env.PUBLIC_URL}/images/WKEins_Impressionen_Button_v3.jpg`} className="img-fluid border mb-1" alt="" /></a>
										<p className="mb-0"><strong>Innenraum:</strong> Hier findet ihr das Video des fertigen WKEINS.</p>
									</Col>
								</Row>
							</div>
							{this.props.children}
						</div>
						{this.getPopupVideo(`${process.env.PUBLIC_URL}/images/WKEins_Impressionen_Button_v2.jpg`, `${process.env.PUBLIC_URL}/video/WKEins_Impressionen_mit_Moebeln_Rundgang.mp4`)}
					</React.Fragment>
				break*/
			
			case 7:
				ciContent = 
					<React.Fragment>
						<div className={"countdown-image-block " + stepImgClass}>
							<div className="content-abs-pos">
								<Row style={{marginTop: "2em"}}>
									<Col xs={{span: 3, offset: 2}} className="bg-light-trans p-3">
										<a href="!#" onClick={this.openPopupVideo1}><img src={`${process.env.PUBLIC_URL}/images/190930_Impressionen_WK_Neubau_2-2.jpg`} className="img-fluid border mb-1" alt="" /></a>
										<p className="mb-0"><strong>Impressionen:</strong> Genießt nochmal die Impressionen vom Rohbau inklusive der spektakulären Drohnenbilder.</p>
									</Col>
									<Col xs={{span: 3, offset: 2}} className="bg-light-trans p-3">
										<a href="https://youtu.be/sN4U1lfSZwg" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/WKEins_360_Rundgang_v2.jpg`} className="img-fluid border mb-1" alt="" /></a>
										<p className="mb-0"><strong>Rundgang:</strong>  Lauft mit uns durch das gesamte Gebäude und schaut Euch alles ganz genau an. Mit <a href="https://youtu.be/sN4U1lfSZwg" target="_blank" rel="noopener noreferrer">diesem Link</a> holt Ihr Euch den 360-Grad-Rundgang ganz einfach auch in die YouTube-App auf Eurem Smartphone.</p>
									</Col>
								</Row>
							</div>
							{this.props.children}
						</div>
						{this.getPopupVideo(`${process.env.PUBLIC_URL}/images/190930_Impressionen_WK_Neubau_thumb.jpg`, `${process.env.PUBLIC_URL}/video/190930_Impressionen_WK_Neubau.mp4`)}
					</React.Fragment>
				break

			default:
					ciContent = 
						<div className={"countdown-image-block " + stepImgClass}>
							{this.props.children}
						</div>
		}

		return ciContent
	}
}

export default CountdownBlock
