import React from 'react'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import {Route, Switch } from "react-router-dom"
import CardDeck from 'react-bootstrap/CardDeck'
import FooterCard from "./footer-card"

const fcFloorLink = () => {
	return (
		<FooterCard imgSrc={`${process.env.PUBLIC_URL}/images/thumb_Floormaps.jpg`} title="Einblicke" linkTarget="/floorplan" linkText="Mehr erfahren">
		Hier könnt Ihr Euch durch die Grundrisse unseres neuen Hauptquartiers klicken.
		</FooterCard>
	)
}

const fcCountdownLink = () => {
	return (
		<FooterCard imgSrc={`${process.env.PUBLIC_URL}/images/Logo_WK_small_pt.png`}  title="Der Countdown" linkTarget="/" linkText="Mehr erfahren">
		Bis zum Umzug gibt es hier immer wieder neues zu entdecken.
		</FooterCard>
	)
}

const fcMitentscheidenLink = () => {
	return (
		<FooterCard imgSrc={`${process.env.PUBLIC_URL}/images/Mitentscheiden.jpg`}  title="Mitentscheiden!" linkTarget="/mitentscheiden" linkText="Mehr erfahren">
			Ihr habt entschieden: Stuhl EINS zieht mit uns ein
		</FooterCard>
	)
}

const fcWebcamLink = () => {
	return (
		<FooterCard imgSrc={`${process.env.PUBLIC_URL}/images/LiveCamThumbnail.jpg`}  title="LiveCam" linkTarget="https://goldbeck190.hi-res-cam.com/" linkText="Mehr erfahren">
			Werft einen aktuellen Blick auf das Gebäude und lasst Euch den bisherigen Bau im Zeitraffer anzeigen.
		</FooterCard>
	)
}

const fcVorfreudeLink = () => {
	return (
		<FooterCard imgSrc={`${process.env.PUBLIC_URL}/images/Statements_Vorfreude_thumb.jpg`}  title="Vorfreude auf WKEins" linkTarget="/vorfreude-statements" linkText="Mehr erfahren">
			Wir haben Euch gefragt, worauf Ihr Euch am meisten freut&nbsp;… Hier gibt es die Vorfreude im Video.
		</FooterCard>
	)
}

const FooterBlock = (props) => {
	return <section id="footer-block" className="mx-n3">
		<CardDeck>
			{/* Block 1 */}
			<Switch>
				<Route path="/" exact component={fcFloorLink} />
				<Route path="/mitentscheiden" component={fcFloorLink} />
				<Route path="/floorplan" component={fcCountdownLink} />
				<Route component={fcCountdownLink} />
			</Switch>
			
			{/* Block 2 */}
			<Switch>
				<Route path="/floorplan" component={fcWebcamLink} />
				<Route component={fcVorfreudeLink} />
			</Switch>
			
			{/* Block 3 */}
			<Switch>
				<Route path="/" exact component={fcMitentscheidenLink} />
				<Route path="/mitentscheiden" component={fcCountdownLink} />
				<Route component={fcMitentscheidenLink} />
			</Switch>
			
		</CardDeck>
	</section>
}

export default FooterBlock