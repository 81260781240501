import React from 'react'
import Container from 'react-bootstrap/Container'
import LoungeBibliothekBlock from '../components/lounge_bibliothek-block'
import FooterBlock from '../components/footer-block'


function LoungeBibliothekPage() {
	return (
		<Container>
			<LoungeBibliothekBlock />
			<FooterBlock />
		</Container>
	);
}

export default LoungeBibliothekPage
