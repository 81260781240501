import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//import { Player } from 'video-react'

//import 'video-react/styles/scss/video-react.scss'

export default class LoungeBibliothekBlock extends Component {
	render = () => {
		return <section id="lounge_bibliothek-block" className="bg-transparent mx-n3">
			<Row className="p-3 bg-white" noGutters="true">
				<Col lg={9} md={12}>
					<h2 className="mb-2">Mitmachen: Wie sollen unsere Lounge und Bibliothek gestaltet sein?</h2>
					<p>Reicht bis Freitag, 13. September 2019, Eure Ideen ein, und mit etwas Glück und Kreativität seid Ihr Teil des Teams! Ob Moodboard, Grundriss, Skizze oder Worte: Schickt einfach eine Mail an das Projektteam Umzug unter <a href="mailto:kommunikation@wolterskluwer.com">kommunikation@wolterskluwer.com</a>.</p>
					<p>Die Kolleginnen und Kollegen mit den besten, kreativsten, praktischsten&nbsp;… Ideen werden dann für die beiden Teams nominiert und nehmen (voraussichtlich) Ende September am finalen Workshop mit dreiform teil.</p>
				</Col>
			</Row>

			<Row noGutters="true" className="mt-2">
				<Col xs={6} className="pr-1">
					<a href="mailto:kommunikation@wolterskluwer.com?subject=Team 1: Ideen für Lounge im Dachgeschoss">
						<img src={`${process.env.PUBLIC_URL}images/2019-08-27_Team_1.jpg`} className="img-fluid border" alt="" />
					</a>
				</Col>
				<Col xs={6} className="pl-1">
					<a href="mailto:kommunikation@wolterskluwer.com?subject=Team 1: Ideen für die Bibliothek">
						<img src={`${process.env.PUBLIC_URL}images/2019-08-27_Team_2.jpg`} className="img-fluid border" alt="" />
					</a>
				</Col>
			</Row>

		</section>
	}
}

