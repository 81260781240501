/* eslint-disable no-extend-native */
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
//import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/styles.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './polyfills'


ReactDOM.render(<App />, document.getElementById('app'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

initIEStuff()

// check for IE

function isIE() {
	// detect IE 6–11
	if (navigator.userAgent.indexOf('MSIE') !==-1 || navigator.appVersion.indexOf('Trident/') > 0) {
		return true
	} else {
		return false
	}
}

function isSupportedIE() {
	// only IE 11 works
	let isSupported = false;
		
		// detect IE 6–10
		if (navigator.userAgent.indexOf('MSIE') !==-1) {
			const myNav = navigator.userAgent.toLowerCase();
			const versionNum = parseInt(myNav.split('msie')[1]);
			if (versionNum > 9) {
				isSupported = true;
			}  else {
				isSupported = false
			}
		}
		if (parseInt(navigator.userAgent.toLowerCase().split("trident/")[1]) >= 7) {
			// IE 11
			isSupported = true;
		}
		return isSupported;
}

function initIEStuff() {
	if(isIE()) {
		if(isSupportedIE()) {
			const bodyEl = document.getElementsByTagName('body')[0]
			bodyEl.classList.add("ie11")
		}
	}
}