import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";

class GoogleAnalytics extends Component {
	componentDidMount() {
		this.logPageChange(
			this.props.location.pathname,
			this.props.location.search
		);
	}

	componentDidUpdate({ location: prevLocation }) {
		const {
			location: { pathname, search }
		} = this.props;
		const isDifferentPathname = pathname !== prevLocation.pathname;
		const isDifferentSearch = search !== prevLocation.search;

		if (isDifferentPathname || isDifferentSearch) {
			this.logPageChange(pathname, search);
		}
	}

	logPageChange(pathname, search = "") {
		const page = pathname + search;
		const { location } = window;
		ReactGA.set({
			page,
			location: `${location.origin}${page}`,
			...this.props.options
		});
		ReactGA.pageview(page);
	}

	render() {
		return null;
	}
}

GoogleAnalytics.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string
	}).isRequired,
	options: PropTypes.object
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
	let isGAEnabled = true;
	if (!window.GA_INITIALIZED) {
		isGAEnabled = true; //process.env.NODE_ENV === "production";

		if (isGAEnabled) {
			ReactGA.initialize("UA-148405505-1");
			window.GA_INITIALIZED = true;
		}
	}

	return isGAEnabled;
};

const logEvent = (category = "User", action = "User action", content = undefined) => {
	if (content === undefined) {
		ReactGA.event({
			category: category,
			action: action
		});
	} else if (typeof content === "number") {
		ReactGA.event({
			category: category,
			action: action,
			//value: content,
			label: String(content)
		});
	} else if (typeof content === "string") {
		ReactGA.event({
			category: category,
			action: action,
			label: content
		});
	}	
};

export default {
	GoogleAnalytics,
	RouteTracker,
	init,
	logEvent
};
