import React, {Component} from 'react'
//import GA from "../utilities/GoogleAnalytics";

import Icon_AgilerProjektraum from '../assets/roomicons/AgilerProjektraum.png'
import Icon_Arbeitsplatz from '../assets/roomicons/Arbeitsplatz.png'
import Icon_Bibliothek from '../assets/roomicons/Bibliothek.png'
import Icon_Dusche from '../assets/roomicons/Dusche.png'
import Icon_Fokus from '../assets/roomicons/Fokus.png'
import Icon_Garderobe from '../assets/roomicons/Garderobe.png'
import Icon_Innenhof from '../assets/roomicons/Innenhof.png'
import Icon_ITlab from '../assets/roomicons/ITlab.png'
import Icon_KanbanZone from '../assets/roomicons/KanbanZone.png'
import Icon_Kinderbuero from '../assets/roomicons/Kinderbuero.png'
import Icon_KonferenzraumL from '../assets/roomicons/KonferenzraumL.png'
import Icon_KonferenzraumM from '../assets/roomicons/KonferenzraumM.png'
import Icon_KonferenzraumS from '../assets/roomicons/KonferenzraumS.png'
import Icon_Lager from '../assets/roomicons/Lager.png'
import Icon_Lounge from '../assets/roomicons/Lounge.png'
import Icon_MeetingKlein from '../assets/roomicons/MeetingKlein.png'
import Icon_Rezeption from '../assets/roomicons/Rezeption.png'
import Icon_Ruheraum from '../assets/roomicons/Ruheraum.png'
import Icon_StandUp from '../assets/roomicons/StandUp.png'
import Icon_Teekueche from '../assets/roomicons/Teekueche.png'
import Icon_Terrasse from '../assets/roomicons/Terrasse.png'
import Icon_Wartebereich from '../assets/roomicons/Wartebereich.png'
import Icon_WC from '../assets/roomicons/WC.png'
import Icon_Trainingsraum from '../assets/roomicons/Trainingsraum.png'
import Icon_Drucker from '../assets/roomicons/Drucker.png'
import Icon_TechBar from '../assets/roomicons/TechBar.png'
import Icon_Post from '../assets/roomicons/Post.png'

import Icon_Lock_closed from '../assets/roomicons/Icon_Lock_closed.svg'
import Icon_Lock_open from '../assets/roomicons/Icon_Lock_open.svg'
import Icon_Person from '../assets/roomicons/Icon_Person.svg'

// import {ReactComponent as IconLockClosed} from '../assets/roomicons/Icon_Lock_closed.svg'
// import {ReactComponent as IconLockOpen} from '../assets/roomicons/Icon_Lock_open.svg'
// import {ReactComponent as IconPerson} from '../assets/roomicons/Icon_Person.svg'

class RoomInfoBoxComp extends Component {
	
	constructor(props) {
		super(props)
		//this.roomInfos = props.roomInfos
		this.state = {
			curRoom: null
		}
	}

	getDefaultText() {
		return (<div className="room-info-box"><div className="room-info-default">
			<p>Wähle das Icon aus und erfahre mehr über jeden Raum im WK EINS!</p>
			{this.props.floorNum !== 4 ? (<p>Klicke auf dein Team und entdecke deinen neuen Arbeitsplatz!</p>) : (null)}
		</div></div>)
	}

	showRoomInfo(level, roomId) {
		const roomInfo = this.getInfoForRoomId(level, roomId)
		if (roomInfo) {
			this.setState({curRoom: roomInfo})
			//console.log(roomInfo)
		}
	}

	hideRoomInfo() {
		this.setState({curRoom: null})
	}

	getInfoForRoomId(level, roomId) {
		let roomInfo = null
		const levelInfos = this.props.roomInfos['Level' + level]
		if (levelInfos) {
			for (let i = 0; i < levelInfos.data.length; i++) {
				if (levelInfos.data[i].id === roomId) {
					roomInfo = levelInfos.data[i]
					break
				}
			}
		}
		return roomInfo
	}

	getBoxColorClass(theColor) {
		let colorClass
		switch (theColor) {
			case "rot":
				colorClass = "red"
				break
			case "grau":
				colorClass = "gray"
				break
			case "blau":
				colorClass = "blue"
				break
			case "orange":
				colorClass = "orange"
				break
			case "grün":
				colorClass = "green"
				break

			default:
				colorClass = "green"
		}
		return colorClass
	}

	getRoomIcon(iconId) {
		let roomIcon
		switch (iconId) {
			case "AgilerProjektraum":
				roomIcon = Icon_AgilerProjektraum
				break
			case "Arbeitsplatz":
				roomIcon = Icon_Arbeitsplatz
				break
			case "Bibliothek":
				roomIcon = Icon_Bibliothek
				break
			case "Dusche":
				roomIcon = Icon_Dusche
				break
			case "Fokus":
				roomIcon = Icon_Fokus
				break
			case "Garderobe":
				roomIcon = Icon_Garderobe
				break
			case "Innenhof":
				roomIcon = Icon_Innenhof
				break
			case "ITlab":
				roomIcon = Icon_ITlab
				break
			case "KanbanZone":
				roomIcon = Icon_KanbanZone
				break
			case "Kinderbuero":
				roomIcon = Icon_Kinderbuero
				break
			case "KonferenzraumL":
				roomIcon = Icon_KonferenzraumL
				break
			case "KonferenzraumM":
				roomIcon = Icon_KonferenzraumM
				break
			case "KonferenzraumS":
				roomIcon = Icon_KonferenzraumS
				break
			case "Lager":
				roomIcon = Icon_Lager
				break
			case "Lounge":
				roomIcon = Icon_Lounge
				break
			case "MeetingKlein":
				roomIcon = Icon_MeetingKlein
				break
			case "Rezeption":
				roomIcon = Icon_Rezeption
				break
			case "Ruheraum":
				roomIcon = Icon_Ruheraum
				break
			case "Stand-Up":
				roomIcon = Icon_StandUp
				break
			case "Teekueche":
				roomIcon = Icon_Teekueche
				break
			case "Terrasse":
				roomIcon = Icon_Terrasse
				break
			case "Wartebereich":
				roomIcon = Icon_Wartebereich
				break
			case "WC":
				roomIcon = Icon_WC
				break
			case "Post":
				roomIcon = Icon_Post
				break
			case "Techbar":
				roomIcon = Icon_TechBar
				break
			case "Drucker":
				roomIcon = Icon_Drucker
				break
			case "Trainingsraum":
				roomIcon = Icon_Trainingsraum
				break
			
			default:
				roomIcon = Icon_Arbeitsplatz

		}
		return roomIcon
	}

	static named = {
		"ndash": "–", // or "\u2013"
		"mdash": "—", // or "\u2014"
		"nbsp": " ",   // or &#160 or "\u00A0"
		"shy": "­" // or &#173; or \u00AD
	};

	convertCharEntities(str) {
		return str.replace(/&([^ ;&]+);/g, (_, ref) => {
			let ch;
			if (ref[0] === "#") {
				let num;
				if (ref[0].toLowerCase() === "x") {
					num = parseInt(ref.substring(2), 16);
				} else {
					num = parseInt(ref, 10);
				}
				ch = String.fromCodePoint(num);
			} else {
				ch = RoomInfoBoxComp.named[ref.toLowerCase()];
			}
			return ch || "";
		});
	}

	renderRoomInfo(roomInfo) {
		let boxClasses = "room-info-box "
		boxClasses += this.getBoxColorClass(roomInfo.farbe)
		return (
			<div className={boxClasses}>
				<div className="head">
					<div className="d-flex justify-content-between">
						<div className="flex-grow-1">
							{roomInfo.raum && roomInfo.raum !== "X" ? (<span className="room-number">Raum {roomInfo.raum}</span>) : 
							(<div style={{display: "block", height: "8px"}}></div>)}
							<div className="room-name">{roomInfo.titel}</div>
						</div>
						<div>
							<img src={this.getRoomIcon(roomInfo.icon)} className="room-icon" alt="Raum Icon" />
						</div>
					</div>
					<div>
						{roomInfo.personen && roomInfo.personen !== "X" ?
						(<><img src={Icon_Person} className="small-icon person" alt="Icon" />
						<span className="ml-2 mr-3">{roomInfo.personen}</span></>)
						: null}
						{roomInfo.buchung && roomInfo.buchung !== "X" ? 
						(<><img src={Icon_Lock_closed} className="small-icon lock" alt="Icon" />
						<span>{roomInfo.buchung}</span></>)
						: null}
					</div>
				</div>

				<div className="info-content">
					{this.convertCharEntities(roomInfo.text)}
				</div>
			</div>
		)
	}

	render() {
		return (<>
				{(this.state.curRoom === null) ?
					(this.getDefaultText())
				: (this.renderRoomInfo(this.state.curRoom))
				}
		</>)
	}
}


const RoomInfoBox = React.forwardRef((props, ref) => <RoomInfoBoxComp {...props} ref={ref} />);
export default RoomInfoBox

