import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Player } from 'video-react'
import 'video-react/styles/scss/video-react.scss'

export default class VideotestBlock extends Component {
	render = () => {
		const videoName = "190726_Eroeffnung_Erkundungsraum"
		return <section id="video-block" className="bg-transparent mx-n3">
			<Row className="p-3 bg-white" noGutters="true">
				<Col lg={9} md={12}>
					<h2 className="mb-2">Eröffnet: Erste Einblicke in das Büro-Design</h2>
					<p>Mit einer kleinen Feier bei „Australian Pies“ und Currywurst haben wir unseren Erkundungsraum eröffnet. Hier können wir schon mal erkunden, was uns in der Wolters-Kluwer-Straße 1 erwarten wird.</p>
				</Col>
			</Row>

			<Row noGutters="true" className="mt-2">
				<Col>
					<Player ref={player => {
							this.player = player
						}}
						videoId="video1"
						poster={`${process.env.PUBLIC_URL}/images/einblicke/190726_WKEINS_EroeffnungsErkundungsraum_02_poster.jpg`}
					>
						<source src={`${process.env.PUBLIC_URL}/video/${videoName}.mp4`} type="video/mp4" />
						{/*<source src={`${process.env.PUBLIC_URL}/video/${videoName}.webm`} type="video/webm" />*/}
					</Player>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={4}>
					<img src={`${process.env.PUBLIC_URL}/images/einblicke/190726_WKEINS_EroeffnungsErkundungsraum_03.jpg`} className="img-fluid border" alt="" />
				</Col>
				<Col xs={4}>
					<img src={`${process.env.PUBLIC_URL}/images/einblicke/190726_WKEINS_EroeffnungsErkundungsraum_01.jpg`} className="img-fluid border" alt="" />
				</Col>
				<Col xs={4}>
					<img src={`${process.env.PUBLIC_URL}/images/einblicke/190802_WKEINS_EroeffnungsErkundungsraum_05.jpg`} className="img-fluid border" alt="" />
				</Col>
			</Row>

		</section>
	}
}

