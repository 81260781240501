import React, {Component} from 'react'

import './zoom-controls.scss'


class ZoomControls extends Component {

	constructor() {
		super()
		this.state = {
			currentZoom: 1
		}
	}

	render () {
		return <div className="zoom-controls">
			<div className="zoom-icon icon-minus mr-1" onClick={this.props.onScaleDown} ></div>
			<div className="zoom-indicator mr-1">{Math.floor(this.props.curScale * 100) + "%"}</div>
			<div className="zoom-icon icon-plus" onClick={this.props.onScaleUp} ></div>
		</div>
	}
}

export default ZoomControls
