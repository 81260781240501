import React from 'react'
import {Link} from "react-router-dom"

const ButtonWithIcon = (props) => {
	let classes = props.extraClass ?  " " + props.extraClass : ""
	const linkTarget = props.linkTarget ? props.linkTarget : "#"
	
	let outerClass = ""
	if (props.atBottomRight) {
		outerClass = "stick-bottom-right"
	}
	let theButton
	if (linkTarget.startsWith('http')) {
		// external link
		theButton = <div className={outerClass}>
			<a href={linkTarget} target="_blank" rel="noopener noreferrer">
				<button className={"button-with-icon" + classes}>
					{props.children}
					<span className="button-icon">&#8594;</span>
				</button>
			</a>
		</div>
	} else {
		theButton = <div className={outerClass}>
			<Link to={linkTarget}>
				<button className={"button-with-icon" + classes}>
					{props.children}
					<span className="button-icon">&#8594;</span>
				</button>
			</Link>
		</div>
	}
	return theButton
}

export default ButtonWithIcon
