import React from 'react'
import Container from 'react-bootstrap/Container'
import VorfreudeBlock from '../components/vorfreude-block'
import FooterBlock from '../components/footer-block'


function VorfreudeStatementsPage() {
	return (
		<Container>
			<VorfreudeBlock />
			<FooterBlock />
		</Container>
	);
}

export default VorfreudeStatementsPage
