import React from 'react'
import Container from 'react-bootstrap/Container'
import MakingOfWKEinsBlock from '../components/making-of-wkeins-block'
import FooterBlock from '../components/footer-block'


function MakingOfWKEinsPage() {
	return (
		<Container>
			<MakingOfWKEinsBlock />
			<FooterBlock />
		</Container>
	);
}

export default MakingOfWKEinsPage
