import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Player } from 'video-react'

import 'video-react/styles/scss/video-react.scss'

export default class VorfreudeBlock extends Component {
	render = () => {
		const videoName = "Statements_Vorfreude"
		return <section id="video-block" className="bg-transparent mx-n3">
			<Row className="p-3 bg-white" noGutters="true">
				<Col lg={9} md={12}>
					<h2 className="mb-2">Vorfreude auf WKEins</h2>
					<p>Wir haben Euch gefragt, worauf Ihr Euch am meisten freut&nbsp;… Hier gibt es die Vorfreude im Video.</p>
				</Col>
			</Row>

			<Row noGutters="true" className="mt-2">
				<Col>
					<Player ref={player => {
							this.player = player
						}}
						videoId="video1"
						poster={`${process.env.PUBLIC_URL}/images/WKEins_Splash.jpg`}
						autoPlay={true}
					>
						<source src={`${process.env.PUBLIC_URL}/video/${videoName}.mp4`} type="video/mp4" />
						{/*<source src={`${process.env.PUBLIC_URL}/video/${videoName}.webm`} type="video/webm" />*/}
					</Player>
				</Col>
			</Row>

		</section>
	}
}

