import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloorselectorButton from './floorselector-button'
import PanZoomElement from './pan-zoom-element'
import GroupSelectorBox from './group-selector-box'
import RoomInfoBox from './room-info-box'

import {RoomInfos, GroupInfos} from '../assets/FloorplanInfos'

// import Floorplan0Img from '../assets/Floorplan_0.jpg'
// import Floorplan1Img from '../assets/Floorplan_1.jpg'
// import Floorplan2Img from '../assets/Floorplan_2.jpg'
// import Floorplan3Img from '../assets/Floorplan_3.jpg'
// import Floorplan4Img from '../assets/Floorplan_4.jpg'
import {ReactComponent as Floorplan0Elements} from '../assets/Floorplan_0.svg'
import {ReactComponent as Floorplan1Elements} from '../assets/Floorplan_1.svg'
import {ReactComponent as Floorplan2Elements} from '../assets/Floorplan_2.svg'
import {ReactComponent as Floorplan3Elements} from '../assets/Floorplan_3.svg'
import {ReactComponent as Floorplan4Elements} from '../assets/Floorplan_4.svg'


class FloorplanBlock extends Component {

	constructor () {
		super()
		this.state = {
			currentFloor: 0,
			groupToShow: "none"
		}
	}

	// getFloorplanScheme() {
	// 	return 7
	// }

	setFloor = (newFloor) => {
		this.setState({
			currentFloor: newFloor
		})
	}

/*	floorplanImages = () => {
		switch (this.state.currentFloor) {
			case 1:
				return {
					img: Floorplan1Img,
					elements: Floorplan1Elements
				}
			case 2:
					return {
						img: Floorplan2Img,
						elements: Floorplan2Elements
					}
			case 3:
					return {
						img: Floorplan3Img,
						elements: Floorplan3Elements
					}
			case 4:
					return {
						img: Floorplan4Img,
						elements: Floorplan4Elements
					}
			default:
					return {
						img: Floorplan0Img,
						elements: Floorplan0Elements
					}
		}
	}*/

	floorplanImages = () => {
		switch (this.state.currentFloor) {
			case 1:
				return Floorplan1Elements
			case 2:
					return Floorplan2Elements
			case 3:
					return Floorplan3Elements
			case 4:
					return Floorplan4Elements
			default:
					return Floorplan0Elements
		}
	}

	showGroupForId = (groupId) => {
		//console.log(groupId)
		this.myRef1.current.showGroupId(groupId)
	}

	hideGroupForId = (groupId) => {
		//console.log(groupId)
		this.myRef1.current.hideAllGroups()
	}

	roomInfoCb = (roomId) => {
		this.myRef2.current.showRoomInfo(this.state.currentFloor, roomId)
	}

	hideRoomInfoCb = () => {
		this.myRef2.current.hideRoomInfo()
	}

	render () {
		const fpContent = this.floorplanImages()
		//const roomInfos = RoomInfos['Level' + this.state.currentFloor]
		const groupInfos = GroupInfos['Level' + this.state.currentFloor]
		this.myRef1 = React.createRef()
		this.myRef2 = React.createRef()
		//console.log(this.myRef)
		return <div className="clearfix">
			<section id="floorplan-block" className="bg-transparent mx-n3">
				<Row className="floorplan-head p-3 bg-white" noGutters="true">
					<Col lg={9} md={12}>
						<h2 className="mb-0">Standort Hürth: Ebene für Ebene</h2>
					</Col>
				</Row>

				<Row noGutters="true" className="mt-2">
					<Col xs={3} className="floorplan-left-special">
						<div className="bg-white mr-2 h-100">
							<div className="bg-wk-light py-2 px-1">
								<div className={"floorplan-scheme floor-" + this.state.currentFloor}>
								</div>
								<Row>
									<Col xs={12} className="pl-1">
										<div className="d-flex justify-content-between">
											<a href="https://www.google.com/maps/@50.8877364,6.9024366,18.29z" target="_blank" rel="noopener noreferrer">
												<h4>
													<span className="small">Neuer Standort Hürth</span><br/>
													Wolters Kluwer Straße 1
												</h4>
											</a>
									
											<a href="https://www.google.com/maps/@50.8877364,6.9024366,18.29z" target="_blank" rel="noopener noreferrer">
												<div className="gps-marker"></div>
											</a>
										</div>
									</Col>
									
								</Row>
							</div>
							<div className="floor-selector-container">
								<div className="floor-selector-box text-left">
									<h2>Etagenauswahl</h2>
									<ul className="floor-selector">
										<FloorselectorButton callback={this.setFloor} floorNum={4} currentFloor={this.state.currentFloor} caption="Etage 4" />
										<FloorselectorButton callback={this.setFloor} floorNum={3} currentFloor={this.state.currentFloor} caption="Etage 3" />
										<FloorselectorButton callback={this.setFloor} floorNum={2} currentFloor={this.state.currentFloor} caption="Etage 2" />
										<FloorselectorButton callback={this.setFloor} floorNum={1} currentFloor={this.state.currentFloor} caption="Etage 1" />
										<FloorselectorButton callback={this.setFloor} floorNum={0} currentFloor={this.state.currentFloor} caption="Erdgeschoss" />
									</ul>
								</div>
							</div>
						</div>
						
					</Col>

					<Col xs={9} className="position-relative floorplan-right-special">
						<div className="floorplan-image-block bg-white">
							<PanZoomElement
								interactiveElements={fpContent}
								refToPass={this.myRef1}
								roomInfoCb={this.roomInfoCb}
								hideRoomInfoCb={this.hideRoomInfoCb}
								altText="Ansicht der Ebene" />
						</div>
						<div className="floorplan-info-block">
							<GroupSelectorBox groupInfos={groupInfos} showGroupCB={this.showGroupForId} hideGroupCB={this.hideGroupForId}/>
							<RoomInfoBox roomInfos={RoomInfos} floorNum={this.state.currentFloor} ref={this.myRef2} />
						</div>
					</Col>
				</Row>
			</section>
		</div>
	}
}

export default FloorplanBlock
