import React from 'react'
import Container from 'react-bootstrap/Container'
import FloorplanBlock from '../components/floorplan-block'
import FooterBlock from '../components/footer-block'


function FloorplanPage() {
	return (
		<Container>
			<FloorplanBlock />
			<FooterBlock />
		</Container>
	);
}

export default FloorplanPage
