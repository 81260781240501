import React, {useEffect} from "react";
//import GA from "../utilities/GoogleAnalytics";

let showGroupCB;
let hideGroupCB;
let teamInfos;

/*const handleBtnClick = evt => {
    const el = evt.target;
    if (!el.classList.contains("active") > -1) {
        //const floor = parseInt(el.dataset.floor) // does not work in IE <= 10
        const floor = parseInt(el.getAttribute("data-floor"))
        if (typeof myCallback === "function") {
            myCallback(floor);
				}
				GA.logEvent("User", "Floorselector", floor)
    }
};*/



const showGroup = (evt) => {
	const el = evt.target;
	const grpId = el.getAttribute("data-groupid")
	//console.log(grpId)
	showGroupCB(grpId)
}

const hideGroup = (evt) => {
	const el = evt.target;
	const grpId = el.getAttribute("data-groupid")
	//console.log(grpId)
	hideGroupCB(grpId)
}

const getTeamsList = theInfos => {
	if (theInfos.data) {
		return (<ul>{
			theInfos.data.map((info, idx) => {
				return(
				<li
					key={idx}
					data-groupid={info.id}
					onMouseEnter={showGroup}
					onMouseLeave={hideGroup}
				>
					{info.name}
				</li>)
			})
	}</ul>)
	} else {
		return null
	}
}

const GroupSelectorBox = (props) => {
	showGroupCB = props.showGroupCB;
	hideGroupCB = props.hideGroupCB;
	teamInfos = props.groupInfos;
	const boxClasses = teamInfos.data ? "group-selector-box" : "group-selector-box invisible"

	useEffect(() => {
		// reset scroll
		const el = document.getElementsByClassName("teams-list")[0]
		el.scrollTop = 0
	})

	return (
		<div className={boxClasses}>
			<h3>{teamInfos.title}</h3>
			<div className="teams-list">
				{getTeamsList(teamInfos)}
			</div>
		</div>
	)
}

export default GroupSelectorBox;
