import React, {Component} from 'react'
import ReactDOM from 'react-dom'

import ZoomControls from './zoom-controls'
import SVGInteractive from './svg-interactive'
import './pan-zoom-element.scss'


class PanZoomElement extends Component {

	curX = -80
	curY = -40
	startX = 0
	startY = 0
	panning = false
	lastWheelEvent = 0
	wheelEventSpacing = 200 // take events every 200ms
	scaleOffset = 0.85 // subtract 15% from every scale

	constructor() {
		super()
		this.state = {
			maxScale: 2.8,
			minScale: 0.6,
			curScale: 1.0,
			scaleStep: 0.2
		}
	}

	componentDidMount() {
		const el = ReactDOM.findDOMNode(this).getElementsByClassName('pan-zoom-element')
		if (el[0] && el[0] instanceof HTMLElement) {
			this.pzEl = el[0]
			this.pzEl.addEventListener('mousedown', this.handleMouseDown);
			this.pzEl.addEventListener('touchstart', this.handleMouseDown);
			this.pzEl.addEventListener('wheel', this.handleWheel);
			this.pzEl.addEventListener('dragstart', this.handleDragStart);
			const theScale = this.state.curScale * this.scaleOffset
			this.pzEl.setAttribute('style', `transform: scale(${theScale}) translate(${this.curX}px, ${this.curY}px)`)
		}
	}

	normalizeTouchPosition(evt, parent) {
		const position = {
			clientX: ('targetTouches' in evt) ? evt.targetTouches[0].pageX : evt.clientX,
			clientY: ('targetTouches' in evt) ? evt.targetTouches[0].pageY : evt.clientY
		};

		while (parent && parent.offsetParent && parent.offsetParent instanceof HTMLElement) {
			position.clientX -= parent.offsetLeft - parent.scrollLeft;
			position.clientY -= parent.offsetTop - parent.scrollTop;
			parent = parent.offsetParent;
		}

		// position.clientX *= (1 / this.state.curScale)
		// position.clientY *= (1 / this.state.curScale)

		return position;
	}

	clampToMargins(pos) {
		//const sf = (1 / this.state.curScale) // scale factor
		let sf = 1
		if (this.state.curScale < 1)
			sf = (1 - this.state.curScale) + 1// scale factor
		else
			sf = (1 / this.state.curScale)
		
		pos.x = Math.max(Math.min(pos.x, this.marginWidth * sf), this.marginLeft * sf) // * sf
		pos.y = Math.max(Math.min(pos.y, this.marginHeight * sf), this.marginTop * sf) // * sf
	}

	handleMouseDown = (evt) => {
		if (!this.panning) {
			const {clientX, clientY} = this.normalizeTouchPosition(evt, this.pzEl)
			//console.log(clientX, clientY)
			this.startX = clientX - this.curX
			this.startY = clientY - this.curY
			// this.startX = clientX + this.pzEl.offsetLeft
			// this.startY = clientY + this.pzEl.offsetTop
			this.panning = true

			const cont = ReactDOM.findDOMNode(this)
			const el = cont.getElementsByClassName('pan-zoom-element')[0]
			this.marginTop = -el.offsetHeight + 80
			this.marginLeft = -el.offsetWidth + 150
			this.marginHeight = cont.offsetHeight - 80
			this.marginWidth = cont.offsetWidth - 150
			//console.log(this.marginTop)

			document.addEventListener('mousemove', this.handleMouseMove);
			document.addEventListener('mouseup', this.handleMouseUp);
			document.addEventListener('mouseout', this.handleMouseUp);
			document.addEventListener('touchmove', this.handleMouseMove);
			document.addEventListener('touchend', this.handleMouseUp);
			this.pzEl.classList.add('grab-cursor')
		}
	}

	handleMouseMove = (evt) => {
		if (this.panning) {
			const {clientX, clientY} = this.normalizeTouchPosition(evt, this.pzEl)
			let newPos = {
				x: clientX - this.startX,
				y: clientY - this.startY
			}
			this.clampToMargins(newPos)
			this.curX = newPos.x
			this.curY = newPos.y
			const theScale = this.state.curScale * this.scaleOffset
			this.pzEl.setAttribute('style', `transform: scale(${theScale}) translate(${this.curX}px, ${this.curY}px)`)
		}
	}

	handleMouseUp = (evt) => {
		if (this.panning) {
			document.removeEventListener('mousemove', this.handleMouseMove)
			document.removeEventListener('mouseup', this.handleMouseUp)
			document.removeEventListener('mouseout', this.handleMouseUp)
			document.removeEventListener('touchmove', this.handleMouseMove)
			document.removeEventListener('touchend', this.handleMouseUp)
			this.panning = false
			this.pzEl.classList.remove('grab-cursor')
		}
	}

	handleDragStart = (evt) => {
		evt.preventDefault()
	}

	handleWheel = (evt) => {
		// prevent scrolling of the page content
		evt.preventDefault()
		evt.cancelBubble = true
		const now = Date.now()

		if (now > this.lastWheelEvent + this.wheelEventSpacing) {
			const {deltaY} = evt
			//console.log(deltaY)
			if (deltaY > 0) {
				// scale up
				this.scaleUp()
			} else if (deltaY < 0) {
				// scale down
				this.scaleDown()
			}

			this.lastWheelEvent = now
		}
	}

	setScale = (theScale) => {
		theScale = Math.round(theScale * 100) / 100
		if (theScale >= this.state.minScale && theScale <= this.state.maxScale) {
			this.setState({
				curScale: theScale
			})
			//console.log(`scale: ${this.state.curScale}`)
			if (this.pzEl) {
				theScale *= this.scaleOffset
				this.pzEl.classList.add('do-transition')
				this.pzEl.addEventListener('transitionend', this.scaleTransitionFinished)
				this.pzEl.setAttribute('style', `transform: scale(${theScale}) translate(${this.curX}px, ${this.curY}px)`)
			}
			// const el = ReactDOM.findDOMNode(this)
			// el.setAttribute('style', 'transform: scale(' + theScale + ')')
		}
	}

	scaleUp = () => {
		this.setScale(this.state.curScale + this.state.scaleStep)
	}

	scaleDown = () => {
		this.setScale(this.state.curScale - this.state.scaleStep)
	}

	scaleTransitionFinished = (evt) => {
		//console.log('scaleTransitionFinished')
		this.pzEl.classList.remove('do-transition')
		this.pzEl.removeEventListener('transitionend', this.scaleTransitionFinished)
	}

	render () {
		return <div className="scale-pan-element">
			<ZoomControls curScale={this.state.curScale} onScaleUp={this.scaleUp} onScaleDown={this.scaleDown} />
			<div className="pan-zoom-element">
				{/* <img src={this.props.imgSrc} className="img-fluid" alt={this.props.altText} /> */}
				<SVGInteractive svgElement={this.props.interactiveElements} roomInfoCb={this.props.roomInfoCb} hideRoomInfoCb={this.props.hideRoomInfoCb} ref={this.props.refToPass}/>
			</div>
		</div>
	}
}

export default PanZoomElement
