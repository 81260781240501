import React from 'react'
import Container from 'react-bootstrap/Container'
import TelefoneboxesBlockV2 from '../components/telefoneboxes-block_v2'
import FooterBlock from '../components/footer-block'


function TelefoneboxesV2Page() {
	return (
		<Container>
			<TelefoneboxesBlockV2 />
			<FooterBlock />
		</Container>
	);
}

export default TelefoneboxesV2Page
