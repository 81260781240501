import React from 'react'
import Container from 'react-bootstrap/Container'
import MitentscheidenBlock from '../components/mitentscheiden-block'
import FooterBlock from '../components/footer-block'


function MitentscheidenPage() {
	return (
		<Container>
			<MitentscheidenBlock />
			<FooterBlock />
		</Container>
	);
}

export default MitentscheidenPage
