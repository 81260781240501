import React from 'react'
import Container from 'react-bootstrap/Container'
import CountdownBlock from '../components/countdown-block'
import FooterBlock from '../components/footer-block'

function CountdownPage(props) {
	return (
		<Container>
			<CountdownBlock match={props.match}></CountdownBlock>
			<FooterBlock></FooterBlock>
		</Container>
	);
}

export default CountdownPage
