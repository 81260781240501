import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ButtonWithIcon from './button-with-icon'


const FooterCard = (props) => {
	let imgTag = "";
	if (props.linkTarget) {
		imgTag = <a href={props.linkTarget}><img src={props.imgSrc} className="card-img" alt="" /></a>
	} else {
		imgTag = <img src={props.imgSrc} className="card-img" alt="" />
	}
	return <Card className="mb-3">
		<Row className="no-gutters flex-fill">
			<Col xs={4}>
				{imgTag}
			</Col>
			<Col xs={8} className="bg-white">
				<Card.Body>
					<Card.Title>{props.title}</Card.Title>
					<Card.Text>
						{props.children}
					</Card.Text>
					{props.linkTarget && <ButtonWithIcon atBottomRight linkTarget={props.linkTarget}>{props.linkText}</ButtonWithIcon>}
				</Card.Body>
				
			</Col>
		</Row>
	</Card>
}

export default FooterCard