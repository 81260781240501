import React from 'react'
import Container from 'react-bootstrap/Container'
import EinblickeBlock from '../components/einblicke-block'
import FooterBlock from '../components/footer-block'


function EinblickePage() {
	return (
		<Container>
			<EinblickeBlock />
			<FooterBlock />
		</Container>
	);
}

export default EinblickePage
